<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.question_id"
        placeholder="请选择题库分类"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="(item, i) in TypeList"
          :key="i"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.type"
        placeholder="请选择题目类型"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option label="单选题" :value="1" />
        <el-option label="多选题" :value="2" />
        <el-option label="填空题" :value="3" />
        <el-option label="简答题" :value="4" />
        <el-option label="判断题" :value="5" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="filter-container">
      <el-button
        class="filter-item"
        style="margin-right: 30px"
        type="success"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
      <el-button
        class="filter-item"
        type="primary"
        @click="handleAdd"
        :disabled="student_answer_num > 0"
      >
        添加题目
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="题库分类" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? scope.row.student_question_bank.question_bank_type.name
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank.type == 1
              ? "单选题"
              : scope.row.student_question_bank.type == 2
              ? "多选题"
              : scope.row.student_question_bank.type == 3
              ? "填空题"
              : scope.row.student_question_bank.type == 4
              ? "简答题"
              : "判断题"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? scope.row.student_question_bank.title
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="分数" min-width="110" align="center">
        <template slot-scope="scope">
          <el-input
            ref="gain"
            v-if="scope.row.isEdit_score"
            @blur="blurClick(scope, scope.$index)"
            :autofocus="true"
            type="number"
            v-model="scope.row.score"
          ></el-input>
          <span v-else>
            {{ scope.row.score ? scope.row.score : "-" }}
            <i class="el-icon-edit"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          <el-input
            ref="gain"
            type="number"
            v-if="scope.row.isEdit_weight"
            @blur="blurClick(scope, scope.$index)"
            :autofocus="true"
            v-model="scope.row.sort_weight"
          ></el-input>
          <span v-else
            >{{ scope.row.sort_weight }}<i class="el-icon-edit"></i
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="280"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            :disabled="student_answer_num > 0"
            @click="handleCreate(scope.row, scope.$index)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            :disabled="student_answer_num > 0"
            @click="handleStatus(scope.$index, '移除')"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 
    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->

    <el-dialog
      title="修改"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item label="设置分数:" prop="score">
          <el-input type="text" v-model="form.score" clearable />
        </el-form-item>
        <el-form-item label="题目排序:" prop="sort_weight">
          <el-input type="text" v-model="form.sort_weight" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        question_id: "",
        type: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        index: "",
        teacher_paper_id: "",
        student_question_bank_id: "",
        score: "",
        sort_weight: "",
      },
      rules: {
        score: [{ required: true, message: "分数不能为空", trigger: "change" }],
        sort_weight: [
          { required: true, message: "排序不能为空", trigger: "change" },
        ],
      },
      btnLoading: false,
      school_list: [],
      TypeList: [],
      student_answer_num: "",
    };
  },
  created() {
    this.listQuery.teacher_paper_id = this.$route.query.id;
    this.listQuery.id = this.$route.query.id;
    this.getList();
    this.getTypeList();
  },
  methods: {
    getTypeList() {
      request({
        url: "/api/teacherend/studentQuestionBank/typeList",
        method: "get",
        // params: this.listQuery,
      }).then((response) => {
        this.TypeList = response.data.data;
      });
    },
    blurClick({ row, column }, index) {
      this.list.forEach((item) => {
        item.isEdit_weight = false;
        item.isEdit_score = false;
        this.$forceUpdate();
      });
      row.index = index;
      if (!row.teacher_paper_id) {
        row.teacher_paper_id = this.listQuery.teacher_paper_id;
      }
      // row.student_question_bank_id=row.student_question_bank.id
      request({
        url: "/api/teacherend/teacherPaper/questionBankStore",
        method: "post",
        data: row,
      }).then(() => {
        this.getList();
        this.$forceUpdate(); //强制更新
      });
    },
    cellClick(row, column, event) {
      if (this.student_answer_num > 0) {
        return;
      }
      if (column.label === "排序") {
        this.$set(row, "isEdit_weight", true);
      } else if (column.label === "分数") {
        this.$set(row, "isEdit_score", true);
      }
      this.$forceUpdate();
    },
    handleAdd() {
      let exist_ids = [];
      this.list.forEach((item) => {
        console.log(item);
        exist_ids.push(item.student_question_bank_id);
      });
      this.$router.push(
        `/question/topic_list?id=${this.listQuery.teacher_paper_id}&exist_ids=${exist_ids}`
      );
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/teacherPaper/detail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.snap) {
          this.list = JSON.parse(response.data.snap);
          this.student_answer_num = response.data.student_answer_num;
          let fil_list = [];
          this.list.forEach((item) => {
            item.isEdit_weight = false;
            item.isEdit_score = false;
            let bank = item.student_question_bank;
            //分类筛选
            if (this.listQuery.type && this.listQuery.question_id) {
              if (
                bank.student_question_bank_type_id ==
                  this.listQuery.question_id &&
                bank.type == this.listQuery.type
              ) {
                fil_list.push(item);
              }
            } else {
              if (
                bank.student_question_bank_type_id ==
                  this.listQuery.question_id ||
                bank.type == this.listQuery.type
              ) {
                fil_list.push(item);
              }
            }
          });
          if (fil_list.length > 0) {
            this.list = fil_list;
          }
        }
        this.listLoading = false;
      });
    },
    async handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate(row, index) {
      console.log(row);
      this.form.id = row.id;
      this.form.index = index;
      this.form.teacher_paper_id = row.teacher_paper_id;
      this.form.student_question_bank_id = row.student_question_bank_id;
      this.form.score = row.score;
      this.form.sort_weight = row.sort_weight;
      this.dialogFormVisible = true;
      //   this.$router.push(`/E-sportsManagement/curriculumList_edit?id=${id}`);
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/teacherPaper/questionBankStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //移除
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该题库, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/teacherPaper/questionBankDel",
            method: "post",
            data: {
              index: val,
              teacher_paper_id: this.listQuery.teacher_paper_id,
            },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-icon-edit {
  margin-left: 5px;
}
</style>
